import { BREADCRUMB_NAME } from 'constants/common';

const Routers = {
  All: '/*',
  Dashboard: '/',
  Login: '/login',
  NotFound: '/not-found',
  // Job Management
  Jobs: '/job-management',
  CreateJob: '/job-management/create',
  DetailJob: '/job-management/detail',
  WorkflowManagement: '/workflow-management',
  // Article Management
  Articles: '/articles-management',
  CreateArticle: '/articles-management/create',
  DetailArticle: '/articles-management/detail',
  InfoArticle: '/articles-management/info',
  // Candidate Management
  Candidates: '/candidates-management',
  CreateMeeting: '/candidates-management/create',
  CreateMeeting2: '/candidates-management/create2',
  CandidateHistory: '/candidates-management/history',
  // User Management
  Users: '/user-management',
  // Training Management
  Training: '/training-management',
  TrainingView: '/training-management/view',
  // Hashtag Management
  Hashtags: '/hashtag-management',
  // Marathon Event
  Marathon: '/marathon-event'
};

export const RoutersBreadCrumb = [
  { path: Routers.Dashboard, breadcrumbName: 'Dashboard' },
  { path: Routers.Login, breadcrumbName: 'Login' },
  { path: Routers.NotFound, breadcrumbName: 'NotFound' },
  { path: Routers.Jobs, breadcrumbName: 'menu.label.jobsManagement' },
  { path: `${Routers.DetailJob}/:id`, breadcrumbName: 'page.jobs.titleDetail' },
  { path: Routers.CreateJob, breadcrumbName: 'page.jobs.titleCreate' },
  { path: Routers.Articles, breadcrumbName: 'Articles Management' },
  { path: Routers.Candidates, breadcrumbName: 'Candidates' },
  {
    path: Routers.CreateMeeting,
    breadcrumbName: localStorage.getItem(BREADCRUMB_NAME) ? String(localStorage.getItem(BREADCRUMB_NAME)) : ''
  },
  { path: `${Routers.DetailArticle}/:id`, breadcrumbName: 'Detail' },
  { path: Routers.CreateArticle, breadcrumbName: 'Create' },
  { path: `${Routers.InfoArticle}/:id`, breadcrumbName: 'Info' },
  { path: Routers.WorkflowManagement, breadcrumbName: 'common.label.view' },
  // User BreadCrumb
  { path: Routers.Users, breadcrumbName: 'User Management' },
  // Training BreadCrumb
  { path: Routers.Training, breadcrumbName: 'Training Management' },
  { path: Routers.TrainingView, breadcrumbName: 'View' },
  { path: Routers.Hashtags, breadcrumbName: 'Hashtag Management' },
  { path: Routers.Marathon, breadcrumbName: 'Marathon Event' }
];

export const PATH_UNUSED_BREADCRUMB = ['/job-management'];

export default Routers;
